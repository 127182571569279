/**
 * Created by Lj on 2018/3/20.
 */

$widthSmall: 90.4%;
$widthPadS: 4.8%;
$baseFont: 16px;

@media all and (max-width: 930px) {
  img {
    max-width: 100%;
  }
  .title {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: .14rem;
    height: auto;
    overflow-y: hidden;
  }
  .content_img {
    img {
      width: auto;
      max-width: 96%;
      padding: 0 2%;
    }
  }
  .main2, .main3, .main4, .main5, .main6, .main7, .main8, .main9, .main10, .header, .footer {
    .mainContent {
      width: $widthSmall;
      margin-left: $widthPadS;
      margin-right: $widthPadS;
    }
  }
  .pcOnly {
    display: none;
  }

  /* ?
  .overX {
    overflow-x: hidden;
    .bgimg {
      width: 1600rem;
    }
  }
  */
  // header
  .header {
    .hasHead {
      padding: 0.20rem 0;
    }
    .headContent {
      position: fixed;
      background: #ffffff;
      /*
      opacity: 0.92;
      */
      z-index: 1000;
      top: 0px;
      left: 0px;
      width: 100%;
    }
    .menu {
      font-size: 0.14rem;
      color: #353535;
      text-align: left;
      height: 0.4rem;
      width: $widthSmall;
      padding-left: $widthPadS;
      padding-right: $widthPadS;
      .flex1 {
        width: 100%;
        text-align: left;
      }
      .logo {
        display: inline-block;
        width: 0.72rem;
      }
      .more {
        display: block;
        width: 0.17rem;
      }
      .menu_title {
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        height: 0.39rem;
        padding-left: 0.3rem;
        padding-right: 0.13rem;
        border-bottom: 0.01rem solid #dddddd;
        #menu_logo {
          width: 0.72rem;
          vertical-align: middle;
        }
        #menu_close {
          width: 0.13rem;
          vertical-align: middle;
        }
      }
      .menu_list {
        position: absolute;
        top: 0;
        right: 0;
        min-height: 6.67rem;
        height: 100%;
        display: none;
        width: 50%;
        padding-left: 50%;
        background: rgba(0, 0, 0, 0.6);
        .hasLayer {
          background: #ffffff;
          height: 100%;
        }
        .nav {
          padding-left: 0.35rem;
          display: block;
          overflow-y: auto;
          height: 100%;
        }
        .flexViewC {
          flex-direction: column;
          align-items: flex-start;
          li {
            a {
              color: #070707;
              font-size: 0.15rem;
              line-height: .67rem;
              font-weight: 400;
              border-bottom: none;
            }
            a:hover, a:active {
              color: #42ABE1;
              border-bottom: none !important;
            }
          }
        }
        .proNav {
          .menu_icon {
            display: inline-block;
            width: 0.18rem;
            height: 0.1rem;
            background: url(../images/phone/xia@2x.png) 0.08rem center no-repeat;
            background-size: 0.1rem;
            transition: all .2s ease-in;
          }
        }
        .proNavsOn {
          text-align: left;
          border-bottom: none !important;
          .menu_icon {
            transform: rotateX(180deg);
          }
        }
        .proNavs {
          position: relative;
          right: 0rem;
          background: #ffffff;
          text-align: left;
          li {
            margin: 0rem;
            padding: 0;
            img {
              width: 0.78rem;
            }
            a {
              /*
              padding: 0.275rem 0;
              */
              // font-size: 18px;
              font-size: 0.12rem;
              line-height: .41rem !important;
              width: 100%;
              color: #646464;
              display: inline-block;
              border-bottom: none !important;
            }
            a:hover, a:active {
              color: #42ABE1;
              border-bottom: none !important;
            }
          }
        }
      }
    }
  }

  // footer
  .footer {
    color: #353535;
    .footer_info {
      padding-top: 0.15rem;
      padding-bottom: 0.15rem;
      .mainContent{
        div {
          padding: 0 5%;
        }
      }
      .logoFoot {
        display: none;
      }

      .wxgzh {
        width: 0.50rem;
      }
      .lianxi {
        width: 1.76rem;
      }
    }
    .footer_copyright {
      background: #5c5a5d;
      font-size: 0.06rem;
      color: #ffffff;
      padding: 0.04rem 0;
    }
  }

  .about {
    .main2 {
      .title {
        img {
          width: 0.97rem
        }
      }
      .content {
        width: 2.72rem;
        margin: 0 auto;
        padding-bottom: 0.19rem;
        padding-top: 0.17rem;
      }
    }
    .main4 {
      overflow: hidden;
      .title {
        img {
          width: 1.82rem
        }
      }
      .rollBox {
        margin-top: 0.25rem;
        margin-bottom: 0.38rem;
        .lunbo {
          max-height: 1.08rem;
          .pic {
            margin: 0rem 0.47rem;
            width: 1.04rem;
            height: 1.04rem;
          }
          .pic:active, .pic:hover {
            margin: 0rem 0.47rem;
            width: 1.04rem;
            height: 1.04rem;
          }
        }
      }
    }
    .main5 {
      text-align: center;
      color: #4E525F;
      font-size: 0.12rem;
      padding-bottom: .16rem;
      .title {
        img {
          width: 0.77rem
        }
      }
      .flexViewB {
        display: block;
        div {
          margin: 0.24rem auto;
          text-align: left;
          img {
            width: 0.17rem;
            display: inline-block;
            margin: 0 0.09rem 0 0.75rem;
            vertical-align: middle;
          }
          p {
            display: inline-block;
            color: #4E525F;
            font-size: 0.1rem;
            vertical-align: middle;

          }
        }
      }
    }
  }
  .join {
    font-size: 0.10rem;
    .main2 {
      .title {
        img {
          width: 3.33rem;
        }
      }
      .content {
        padding-top: 0.21rem;
        padding-bottom: 0.18rem;
        p {
          color: #464646;
          margin-top: 0.09rem;
          font-size: 0.09rem;
        }
        li {
          div {
            background: url(../images/zhaopin-2-1-2.png) center center no-repeat;
            width: 0.3rem;
            background-size: 100%;
          }
        }
        li:hover, li:active, .checked {
          div {
            background: url(../images/zhaopin-2-1-1.png) center center no-repeat;
            width: 0.30rem;
            background-size: 100%;
          }
        }
      }
    }
    .main3 {
      .menu_title ul {
        padding: .09rem .15rem;
      }
      .content {
        margin-top: 0;
        margin-bottom: 0.09rem;
        overflow: hidden;
        ul {
          padding: .12rem .15rem;
        }
        .content_body {
          font-size: 0.12rem;
          padding: .1rem 0.05rem;
          .join_btn {
            width: 0.61rem;
          }
          div {
            margin: .17rem auto .07rem;
          }
        }
      }
    }
  }
  // index
  .index {
    .indexmain3 {
      overflow: hidden;
      .title {
        img{
          width: 6.45rem;
          max-width: 90%;
        }
      }
      .content {
        margin: .32rem 0 0.35rem;
        height: 0.86rem;
        li {
          width: 0.54rem;
          height: 0.82rem;
          float: left;
          padding: .02rem 0.12rem;
          margin: 0 1.172%;
          font-size: 0.09rem;
          img {
            margin: 0 auto .02rem;
          }
          p {
            display: none;
          }
        }
      }
      .product_index {
        min-height: 0.86rem;
      }
    }
    .indexmain4 {
      .title {
        img {
          width: 2.30rem;
        }
      }
      .content {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0.35rem;
        a {
          width: 0.35rem;
          height: 0.35rem;
          font-size: 0.09rem;
          margin: 0 0.18rem;
          p{
            height: 0.35rem;
            margin-top: 0.05rem;
          }
        }
      }
    }
  }

  // product common
  .product {
    // common
    .blueBjContent {
      img {
        width: .235rem;
        height: .235rem;
        vertical-align: middle;
      }
      flex-wrap: wrap;
      text-align: center;
      font-size: 0.09rem;
      color: #666666;
      .div_content {
        background: #ffffff;
        border-radius: 0.06rem;
        text-align: left;
        padding: 0.16rem 0.1rem;
        width: 1.2rem;
        height: 1.08rem;
        margin: 0 0.22rem 0.05rem 0;
        .div_title {
          font-size: 0.16rem;
          padding-top: 0.13rem;
          padding-bottom: 0.13rem;
          color: #222222;
          display: inline-block;
          vertical-align: middle;
        }
        p {
          line-height: .13rem;
        }
      }
      .div_content:nth-child(3n) {
        margin: 0 0.22rem 0.05rem 0;
      }
      .div_content:nth-child(2n) {
        margin: 0 0 0.05rem;
      }
      .div_content:hover {
        background: #1A6EFF;
        color: #ffffff;
        .div_title {
          color: #ffffff;
        }
      }
    }

    .solution {
      position: absolute;
      left: 0;
      bottom: 0.05rem;
      text-align: center;
      width: 100%;
      img {
        width: 2.21rem;
      }
    }

    .product_infoB {
      display: none;
    }
    .product_infoS {
      display: table;
    }
    .product_info {
      td:first-child {
        width: 0.77rem;
      }
      td {
        padding: .1rem .15rem;
      }
      thead {
        font-size: 0.14rem;
      }
    }
    .upgrade {
      padding: .14rem 0 .14rem;
      ul {
        display: block;
        height: 1.43rem;
        overflow: hidden;
      }
      ul {
        color: #555555;
        font-size: 0.12rem;
        .upgrade_content {
          // 114 143
          padding: 0.11rem 0 0.12rem;
          border: 1px solid #fff;
          width: 1.12rem;
          height: 1.18rem;
          margin: 0rem 0.85%;
          font-size: 0.09rem;
          img {
            width: 0.42rem;
          }
          span {
            font-size: 0.12rem;
            margin: 0.05rem 0;
          }
          p {
            line-height: 0.12rem;
          }
        }
        .upgrade_content:active, .upgrade_content:hover {
          padding: 0.21rem 0 0.12rem;
          background: #259CDA;
          color: #ffffff;
          border: 1px solid #259CDA;
          span {
            color: #ffffff;
          }
        }
      }
    }
    .scene {
      padding: .24rem 1% .47rem;
      li {
        margin: 0.08rem 0;
        width: 2rem;
        span {
          line-height: 0.3rem;
          font-size: 0.12rem;
        }
      }
      .flexViewC {
        flex-direction: column;
      }
    }

    // main
    .main2 {
      .content {
        padding-top: 0.2rem;
        padding-bottom: .2rem;
        flex-wrap: wrap;
        .hastop {
          margin-top: .05rem;
        }
        .product_img {
          width: 24.8%;
        }
        .product_body {
          /*
          width: 62%;
          */
          width: 100%;
          margin-left: 0.15rem;
          span {
            margin-bottom: .12rem;
          }
        }
      }
      .imgB {
        display: none;
      }
      .imgS {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        img {
          width: 0.52rem;
          padding: 0 0.13rem;
        }
      }
      .flexViewB {
        padding-bottom: 0.21rem;
      }
    }
    .main3 {
      padding-bottom: 0.13rem;
    }
  }

  .sharedock {
    .main2 {
      .title {
        img {
          width: 4.925 * 100 * 0.005rem;
        }
      }
    }
    .main3 {
      .title {
        img {
          width: 118.5 * 0.005rem;
        }
      }
    }
    .main7 {
      .title {
        img {
          width: 118.5 * 0.005rem;;
        }
      }
      .content {
        padding-top: .59 * 100 * 0.005rem;
        padding-bottom: .51 * 100 * 0.005rem;
      }
    }
    .main4 {
      .title {
        img {
          width: 2.32 * 100 * 0.005rem;
        }
      }
    }
    .indexmain5 {
      position: relative;
      .title {
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        img {
          width: 1.18*100 * 0.005rem;
        }
      }
    }
    .indexmain6 {
      position: relative;
      min-height: 2.5rem;
      background-color: #282E45;
      .main6 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        .title {
          text-align: center;
          img {
            width: 115.5 * 0.005rem;
          }
        }
        .content {
          img {
            width: 0.87 * 100 * 0.005rem;
            height: 0.87 * 100 * 0.005rem;
          }
          padding-bottom: 0.81 * 100 * 0.005rem;
          padding-top: 0.87 * 100 * 0.005rem;
          text-align: center;
          color: #ffffff;
          font-size: 0.09rem;
        }
        .div_content {
          background: #5242D1;
          border-radius: 10 * 0.005rem;
          padding: 0.31 * 100 * 0.005rem;
          width: 2.63 * 100 * 0.005rem;
          height: 2.11 * 100 * 0.005rem;
          .div_title {
            font-size: 21 * 0.005rem;
            padding-top: 13 * 0.005rem;
            padding-bottom: 16 * 0.005rem;
          }
          p {
            line-height: 20 * 0.005rem;
          }
        }
        .div_content:first-child {
          margin-right: 0.2rem;
          background: #0084FF;
        }
      }
    }
  }

  .babyBurglar {
    .main2 {
      .title {
        img {
          width: 3.04 * 100 * 0.005rem;
        }
      }
    }
    .main6 {
      .title {
        margin-top: 47 * 0.005rem;
        img {
          width: 3.03 * 100 * 0.005rem;
        }
      }
      .content {
        margin-top: 28 * 0.005rem;
        img {
          width: 607 * 0.005rem;
        }
      }
    }
    .main7 {
      .title {
        margin-top: 29 * 0.005rem;

        img {
          width: 3.03 * 100 * 0.005rem;
        }
      }
      .content {
        margin-top: 68 * 0.005rem;
        padding-bottom: 116 * 0.005rem;

        img {
          width: 429 * 0.005rem;
        }
      }
    }
    .main5 {
      .title {
        img {
          width: 1.8 * 100 * 0.005rem;
        }
      }
      .content {
        padding-top: 0.59 * 100 * 0.005rem;
        padding-bottom: 0.51 * 100 * 0.005rem;
        /*      display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        flex-direction: row;
        justify-content: space-around;*/
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        text-align: left;
        .hastop {
          margin-top: 0.28 * 100 * 0.005rem;
        }
        .product_img {
          width: 1rem;
          text-align: right;
          img {
            width: 3.5 * 100 * 0.005rem;
          }
        }
        .product_body {
          font-size: 0.09rem;
          color: #474747;
          width: 51%;
          margin-left: 40 * 0.005rem;
          max-width: 386 * 0.005rem;
          span {
            display: block;
            margin-bottom: 0.32 * 100 * 0.005rem;
          }
          p {
            font-size: .09rem;
            color: #767676;
            line-height: .12rem;
          }
        }
      }
    }
    .indexmain4 {
      position: relative;
      background: url('../images/bj-ying-4.png') left top;
      background-size: 1600 * 0.005rem 839 * 0.005rem;
      .main4 {
        /*
        position: absolute;
        left: 0;
        top: 0;
        */
        width: 100%;
        .title {
          text-align: center;
          img {
            width: 189 * 0.005rem;
          }
        }
        .mainContent {
          padding-bottom: 0.88 * 100 * 0.005rem;
          padding-top: 0.36 * 100 * 0.005rem;
        }
      }
    }
  }

  .sharewheelchair {
    .main2 {
      .title {
        img {
          width: 2.411 * 100 * 0.005rem;
        }
      }
    }
    .main5 {
      .title {
        img {
          width: 1.185 * 100 * 0.005rem;
        }
      }
      .content {
        margin: 49 * 0.005rem 0 106 * 0.005rem;
      }
      .borderGreyDiv {
        font-size: 0.09rem;
        color: #474747;
        div {
          border: 1px solid #E1E3E3;
          border-radius: 8 * 0.005rem;
          width: 320 * 0.005rem;
          height: .62rem;
          padding: .1rem 0;
          margin: 15.5 * 0.005rem 26 * 0.005rem;
        }
        img {
          margin-bottom: 24 * 0.005rem;
          width: 65 * 0.005rem;
        }
      }
    }
    .main7 {
      .title {
        img {
          width: 2.32 * 100 * 0.005rem;
        }
      }
    }

    .indexmain4 {
      position: relative;
      .title {
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        img {
          width: 2.411*100 * 0.005rem;
        }
      }
    }
    .indexmain6 {
      position: relative;
      min-height: 3.8rem;
      background: #124F9B;
      .main6 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        .title {
          text-align: center;
          img {
            width: 118 * 0.005rem;
          }
        }
        .mainContent {
          padding-bottom: 0.33rem;
          padding-top: 0.3rem;
        }

      }
    }
  }

  .escortbed {
    .main2 {
      .title {
        img {
          width: 2.4725 * 100 * 0.005rem;
        }
      }
    }
    .indexmain4 {
      position: relative;
      .title {
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        img {
          width: 2.73*100 * 0.005rem;
        }
      }
    }
    .main5 {
      .title {
        img {
          width: 1.185 * 100 * 0.005rem;
        }
      }
      .content {
        margin: 77 * 0.0025rem 0 137 * 0.0025rem;
      }
      .borderGreyDiv {
        font-size: 0.09rem;
        color: #6D6D6D;
        flex-wrap: wrap;
        div {
          border: 1px solid #E9EAEE;
          border-radius: 10 * 0.005rem;
          width: 201 * 0.005rem;
          height: 1.23rem;
          padding: 24 * 0.005rem 21 * 0.005rem;
          margin: 0.05rem 17 * 0.005rem;
        }
        p{
          line-height: 0.12rem;
        }
        .content_title {
          color: #474747;
          font-size: 20 * 0.005rem;
          padding: 0.12rem 0;
        }
        img {
          width: 72 * 0.005rem;
        }
      }
    }
    .indexmain6 {
      position: relative;
      min-height: 2.49rem;
      background: #124F9B;
      .main6 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        .title {
          text-align: center;
          img {
            width: 118 * 0.005rem;
          }
        }
        .mainContent {
          padding-bottom: 0.88 * 100 * 0.005rem;
          padding-top: 0.66 * 100 * 0.005rem;
        }

      }
    }
    .main7 {
      .title {
        img {
          width: 1.18 * 100 * 0.005rem;
        }
      }
      .content {
        padding-top: 60 * 0.005rem;
      }
      .flexViewC {
        background: #ffffff;
        .flex1 {
          width: 464 * 0.005rem;
          p {
            padding: 0 63 * 0.005rem;
            color: #818181;
            font-size: 0.09rem;
            margin-top: 41 * 0.005rem;
          }
          span {
            padding: 0 63 * 0.005rem;
            color: #222222;
            font-size: 20 * 0.005rem;
          }
        }
      }
    }
    .main8 {
      .title {
        img {
          width: 1.18 * 100 * 0.005rem;
        }
      }
      .content {
        padding-top: 66 * 0.005rem;
        padding-bottom: .4rem;
      }
      table {
        table-layout: fixed;
        thead {
          td:first-child {
            padding: 0;
            position: relative;
            background: linear-gradient(18deg, transparent 49.5%, #f5f5f5 49.5%, #f5f5f5 50.5%, transparent 50.5%);
            p {
              padding: 0 * 0.005rem 6 * 0.005rem;
            }
          }
        }
      }
    }
  }

  .indexmain9 {
    position: relative;
    background-color: #0E95EF;
    /*
    background-color: #0B6CDB;
    */
    .main9 {
      /*
      position: absolute;
      left: 0;
      top: 0;
      */
      width: 100%;
      .title {
        text-align: center;
        img {
          width: 1.18 * 100 * 0.005rem;
        }
      }
      .content {
        width: 94%;
        margin: 0 auto;
        /*
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        */
      }
      .content_body {
        color: #FFFFFF;
        font-size: 9px;
        // width: 50%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: stretch;
        img {
          width: 49 * 0.005rem;
          padding-right: 0.1rem;
        }
        .div_title {
          font-size: 18 * 0.005rem;
          padding-bottom: 0.1rem;
        }
        .div_content {
          text-align: left;
          width: 50%;
          p {
            width: 276 * 0.005rem;
          }
          padding-left: 89 * 0.0025rem;
          padding-top: .15rem;
          padding-bottom: 0 * 0.0025rem;
          padding-right: 46 * 0.005rem;
          // 24
          margin-bottom: 81 * 0.005rem;
          margin-top: 0;
          position: relative;
        }
      }
      .content_body:first-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.49);
        .div_content {
          padding-top: 0 * 0.005rem;
          padding-bottom: 0.15rem;
          margin-top: 47 * 0.005rem;
          margin-bottom: 0;
        }
        .div_content:first-child:before {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 100%;
          background: rgba(255, 255, 255, 0.49);
        }
      }
    }
  }

  .main10 {
    .title {
      img {
        width: 1.18 * 100 * 0.005rem;
      }
    }
    .flexViewC {
      padding-top: 47 * 0.005rem;
      padding-bottom: 51 * 0.005rem;
      .flex1 {
        margin: 0 4 * 0.005rem;
      }
    }
  }


  // 375结束
}