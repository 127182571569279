/**
 * Created by Lj on 2018/3/20.
 */
@media all and (max-width: 930px) {
  img {
    max-width: 100%; }
  .title {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: .14rem;
    height: auto;
    overflow-y: hidden; }
  .content_img img {
    width: auto;
    max-width: 96%;
    padding: 0 2%; }
  .main2 .mainContent, .main3 .mainContent, .main4 .mainContent, .main5 .mainContent, .main6 .mainContent, .main7 .mainContent, .main8 .mainContent, .main9 .mainContent, .main10 .mainContent, .header .mainContent, .footer .mainContent {
    width: 90.4%;
    margin-left: 4.8%;
    margin-right: 4.8%; }
  .pcOnly {
    display: none; }
  /* ?
  .overX {
    overflow-x: hidden;
    .bgimg {
      width: 1600rem;
    }
  }
  */
  .header .hasHead {
    padding: 0.20rem 0; }
  .header .headContent {
    position: fixed;
    background: #ffffff;
    /*
      opacity: 0.92;
      */
    z-index: 1000;
    top: 0px;
    left: 0px;
    width: 100%; }
  .header .menu {
    font-size: 0.14rem;
    color: #353535;
    text-align: left;
    height: 0.4rem;
    width: 90.4%;
    padding-left: 4.8%;
    padding-right: 4.8%; }
    .header .menu .flex1 {
      width: 100%;
      text-align: left; }
    .header .menu .logo {
      display: inline-block;
      width: 0.72rem; }
    .header .menu .more {
      display: block;
      width: 0.17rem; }
    .header .menu .menu_title {
      display: -webkit-flex;
      /* Safari */
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      height: 0.39rem;
      padding-left: 0.3rem;
      padding-right: 0.13rem;
      border-bottom: 0.01rem solid #dddddd; }
      .header .menu .menu_title #menu_logo {
        width: 0.72rem;
        vertical-align: middle; }
      .header .menu .menu_title #menu_close {
        width: 0.13rem;
        vertical-align: middle; }
    .header .menu .menu_list {
      position: absolute;
      top: 0;
      right: 0;
      min-height: 6.67rem;
      height: 100%;
      display: none;
      width: 50%;
      padding-left: 50%;
      background: rgba(0, 0, 0, 0.6); }
      .header .menu .menu_list .hasLayer {
        background: #ffffff;
        height: 100%; }
      .header .menu .menu_list .nav {
        padding-left: 0.35rem;
        display: block;
        overflow-y: auto;
        height: 100%; }
      .header .menu .menu_list .flexViewC {
        flex-direction: column;
        align-items: flex-start; }
        .header .menu .menu_list .flexViewC li a {
          color: #070707;
          font-size: 0.15rem;
          line-height: .67rem;
          font-weight: 400;
          border-bottom: none; }
        .header .menu .menu_list .flexViewC li a:hover, .header .menu .menu_list .flexViewC li a:active {
          color: #42ABE1;
          border-bottom: none !important; }
      .header .menu .menu_list .proNav .menu_icon {
        display: inline-block;
        width: 0.18rem;
        height: 0.1rem;
        background: url(../images/phone/xia@2x.png) 0.08rem center no-repeat;
        background-size: 0.1rem;
        transition: all .2s ease-in; }
      .header .menu .menu_list .proNavsOn {
        text-align: left;
        border-bottom: none !important; }
        .header .menu .menu_list .proNavsOn .menu_icon {
          transform: rotateX(180deg); }
      .header .menu .menu_list .proNavs {
        position: relative;
        right: 0rem;
        background: #ffffff;
        text-align: left; }
        .header .menu .menu_list .proNavs li {
          margin: 0rem;
          padding: 0; }
          .header .menu .menu_list .proNavs li img {
            width: 0.78rem; }
          .header .menu .menu_list .proNavs li a {
            /*
              padding: 0.275rem 0;
              */
            font-size: 0.12rem;
            line-height: .41rem !important;
            width: 100%;
            color: #646464;
            display: inline-block;
            border-bottom: none !important; }
          .header .menu .menu_list .proNavs li a:hover, .header .menu .menu_list .proNavs li a:active {
            color: #42ABE1;
            border-bottom: none !important; }
  .footer {
    color: #353535; }
    .footer .footer_info {
      padding-top: 0.15rem;
      padding-bottom: 0.15rem; }
      .footer .footer_info .mainContent div {
        padding: 0 5%; }
      .footer .footer_info .logoFoot {
        display: none; }
      .footer .footer_info .wxgzh {
        width: 0.50rem; }
      .footer .footer_info .lianxi {
        width: 1.76rem; }
    .footer .footer_copyright {
      background: #5c5a5d;
      font-size: 0.06rem;
      color: #ffffff;
      padding: 0.04rem 0; }
  .about .main2 .title img {
    width: 0.97rem; }
  .about .main2 .content {
    width: 2.72rem;
    margin: 0 auto;
    padding-bottom: 0.19rem;
    padding-top: 0.17rem; }
  .about .main4 {
    overflow: hidden; }
    .about .main4 .title img {
      width: 1.82rem; }
    .about .main4 .rollBox {
      margin-top: 0.25rem;
      margin-bottom: 0.38rem; }
      .about .main4 .rollBox .lunbo {
        max-height: 1.08rem; }
        .about .main4 .rollBox .lunbo .pic {
          margin: 0rem 0.47rem;
          width: 1.04rem;
          height: 1.04rem; }
        .about .main4 .rollBox .lunbo .pic:active, .about .main4 .rollBox .lunbo .pic:hover {
          margin: 0rem 0.47rem;
          width: 1.04rem;
          height: 1.04rem; }
  .about .main5 {
    text-align: center;
    color: #4E525F;
    font-size: 0.12rem;
    padding-bottom: .16rem; }
    .about .main5 .title img {
      width: 0.77rem; }
    .about .main5 .flexViewB {
      display: block; }
      .about .main5 .flexViewB div {
        margin: 0.24rem auto;
        text-align: left; }
        .about .main5 .flexViewB div img {
          width: 0.17rem;
          display: inline-block;
          margin: 0 0.09rem 0 0.75rem;
          vertical-align: middle; }
        .about .main5 .flexViewB div p {
          display: inline-block;
          color: #4E525F;
          font-size: 0.1rem;
          vertical-align: middle; }
  .join {
    font-size: 0.10rem; }
    .join .main2 .title img {
      width: 3.33rem; }
    .join .main2 .content {
      padding-top: 0.21rem;
      padding-bottom: 0.18rem; }
      .join .main2 .content p {
        color: #464646;
        margin-top: 0.09rem;
        font-size: 0.09rem; }
      .join .main2 .content li div {
        background: url(../images/zhaopin-2-1-2.png) center center no-repeat;
        width: 0.3rem;
        background-size: 100%; }
      .join .main2 .content li:hover div, .join .main2 .content li:active div, .join .main2 .content .checked div {
        background: url(../images/zhaopin-2-1-1.png) center center no-repeat;
        width: 0.30rem;
        background-size: 100%; }
    .join .main3 .menu_title ul {
      padding: .09rem .15rem; }
    .join .main3 .content {
      margin-top: 0;
      margin-bottom: 0.09rem;
      overflow: hidden; }
      .join .main3 .content ul {
        padding: .12rem .15rem; }
      .join .main3 .content .content_body {
        font-size: 0.12rem;
        padding: .1rem 0.05rem; }
        .join .main3 .content .content_body .join_btn {
          width: 0.61rem; }
        .join .main3 .content .content_body div {
          margin: .17rem auto .07rem; }
  .index .indexmain3 {
    overflow: hidden; }
    .index .indexmain3 .title img {
      width: 6.45rem;
      max-width: 90%; }
    .index .indexmain3 .content {
      margin: .32rem 0 0.35rem;
      height: 0.86rem; }
      .index .indexmain3 .content li {
        width: 0.54rem;
        height: 0.82rem;
        float: left;
        padding: .02rem 0.12rem;
        margin: 0 1.172%;
        font-size: 0.09rem; }
        .index .indexmain3 .content li img {
          margin: 0 auto .02rem; }
        .index .indexmain3 .content li p {
          display: none; }
    .index .indexmain3 .product_index {
      min-height: 0.86rem; }
  .index .indexmain4 .title img {
    width: 2.30rem; }
  .index .indexmain4 .content {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0.35rem; }
    .index .indexmain4 .content a {
      width: 0.35rem;
      height: 0.35rem;
      font-size: 0.09rem;
      margin: 0 0.18rem; }
      .index .indexmain4 .content a p {
        height: 0.35rem;
        margin-top: 0.05rem; }
  .product .blueBjContent {
    flex-wrap: wrap;
    text-align: center;
    font-size: 0.09rem;
    color: #666666; }
    .product .blueBjContent img {
      width: .235rem;
      height: .235rem;
      vertical-align: middle; }
    .product .blueBjContent .div_content {
      background: #ffffff;
      border-radius: 0.06rem;
      text-align: left;
      padding: 0.16rem 0.1rem;
      width: 1.2rem;
      height: 1.08rem;
      margin: 0 0.22rem 0.05rem 0; }
      .product .blueBjContent .div_content .div_title {
        font-size: 0.16rem;
        padding-top: 0.13rem;
        padding-bottom: 0.13rem;
        color: #222222;
        display: inline-block;
        vertical-align: middle; }
      .product .blueBjContent .div_content p {
        line-height: .13rem; }
    .product .blueBjContent .div_content:nth-child(3n) {
      margin: 0 0.22rem 0.05rem 0; }
    .product .blueBjContent .div_content:nth-child(2n) {
      margin: 0 0 0.05rem; }
    .product .blueBjContent .div_content:hover {
      background: #1A6EFF;
      color: #ffffff; }
      .product .blueBjContent .div_content:hover .div_title {
        color: #ffffff; }
  .product .solution {
    position: absolute;
    left: 0;
    bottom: 0.05rem;
    text-align: center;
    width: 100%; }
    .product .solution img {
      width: 2.21rem; }
  .product .product_infoB {
    display: none; }
  .product .product_infoS {
    display: table; }
  .product .product_info td:first-child {
    width: 0.77rem; }
  .product .product_info td {
    padding: .1rem .15rem; }
  .product .product_info thead {
    font-size: 0.14rem; }
  .product .upgrade {
    padding: .14rem 0 .14rem; }
    .product .upgrade ul {
      display: block;
      height: 1.43rem;
      overflow: hidden; }
    .product .upgrade ul {
      color: #555555;
      font-size: 0.12rem; }
      .product .upgrade ul .upgrade_content {
        padding: 0.11rem 0 0.12rem;
        border: 1px solid #fff;
        width: 1.12rem;
        height: 1.18rem;
        margin: 0rem 0.85%;
        font-size: 0.09rem; }
        .product .upgrade ul .upgrade_content img {
          width: 0.42rem; }
        .product .upgrade ul .upgrade_content span {
          font-size: 0.12rem;
          margin: 0.05rem 0; }
        .product .upgrade ul .upgrade_content p {
          line-height: 0.12rem; }
      .product .upgrade ul .upgrade_content:active, .product .upgrade ul .upgrade_content:hover {
        padding: 0.21rem 0 0.12rem;
        background: #259CDA;
        color: #ffffff;
        border: 1px solid #259CDA; }
        .product .upgrade ul .upgrade_content:active span, .product .upgrade ul .upgrade_content:hover span {
          color: #ffffff; }
  .product .scene {
    padding: .24rem 1% .47rem; }
    .product .scene li {
      margin: 0.08rem 0;
      width: 2rem; }
      .product .scene li span {
        line-height: 0.3rem;
        font-size: 0.12rem; }
    .product .scene .flexViewC {
      flex-direction: column; }
  .product .main2 .content {
    padding-top: 0.2rem;
    padding-bottom: .2rem;
    flex-wrap: wrap; }
    .product .main2 .content .hastop {
      margin-top: .05rem; }
    .product .main2 .content .product_img {
      width: 24.8%; }
    .product .main2 .content .product_body {
      /*
          width: 62%;
          */
      width: 100%;
      margin-left: 0.15rem; }
      .product .main2 .content .product_body span {
        margin-bottom: .12rem; }
  .product .main2 .imgB {
    display: none; }
  .product .main2 .imgS {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center; }
    .product .main2 .imgS img {
      width: 0.52rem;
      padding: 0 0.13rem; }
  .product .main2 .flexViewB {
    padding-bottom: 0.21rem; }
  .product .main3 {
    padding-bottom: 0.13rem; }
  .sharedock .main2 .title img {
    width: 2.4625rem; }
  .sharedock .main3 .title img {
    width: 0.5925rem; }
  .sharedock .main7 .title img {
    width: 0.5925rem; }
  .sharedock .main7 .content {
    padding-top: 0.295rem;
    padding-bottom: 0.255rem; }
  .sharedock .main4 .title img {
    width: 1.16rem; }
  .sharedock .indexmain5 {
    position: relative; }
    .sharedock .indexmain5 .title {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center; }
      .sharedock .indexmain5 .title img {
        width: 0.59rem; }
  .sharedock .indexmain6 {
    position: relative;
    min-height: 2.5rem;
    background-color: #282E45; }
    .sharedock .indexmain6 .main6 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%; }
      .sharedock .indexmain6 .main6 .title {
        text-align: center; }
        .sharedock .indexmain6 .main6 .title img {
          width: 0.5775rem; }
      .sharedock .indexmain6 .main6 .content {
        padding-bottom: 0.405rem;
        padding-top: 0.435rem;
        text-align: center;
        color: #ffffff;
        font-size: 0.09rem; }
        .sharedock .indexmain6 .main6 .content img {
          width: 0.435rem;
          height: 0.435rem; }
      .sharedock .indexmain6 .main6 .div_content {
        background: #5242D1;
        border-radius: 0.05rem;
        padding: 0.155rem;
        width: 1.315rem;
        height: 1.055rem; }
        .sharedock .indexmain6 .main6 .div_content .div_title {
          font-size: 0.105rem;
          padding-top: 0.065rem;
          padding-bottom: 0.08rem; }
        .sharedock .indexmain6 .main6 .div_content p {
          line-height: 0.1rem; }
      .sharedock .indexmain6 .main6 .div_content:first-child {
        margin-right: 0.2rem;
        background: #0084FF; }
  .babyBurglar .main2 .title img {
    width: 1.52rem; }
  .babyBurglar .main6 .title {
    margin-top: 0.235rem; }
    .babyBurglar .main6 .title img {
      width: 1.515rem; }
  .babyBurglar .main6 .content {
    margin-top: 0.14rem; }
    .babyBurglar .main6 .content img {
      width: 3.035rem; }
  .babyBurglar .main7 .title {
    margin-top: 0.145rem; }
    .babyBurglar .main7 .title img {
      width: 1.515rem; }
  .babyBurglar .main7 .content {
    margin-top: 0.34rem;
    padding-bottom: 0.58rem; }
    .babyBurglar .main7 .content img {
      width: 2.145rem; }
  .babyBurglar .main5 .title img {
    width: 0.9rem; }
  .babyBurglar .main5 .content {
    padding-top: 0.295rem;
    padding-bottom: 0.255rem;
    /*      display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        flex-direction: row;
        justify-content: space-around;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: left; }
    .babyBurglar .main5 .content .hastop {
      margin-top: 0.14rem; }
    .babyBurglar .main5 .content .product_img {
      width: 1rem;
      text-align: right; }
      .babyBurglar .main5 .content .product_img img {
        width: 1.75rem; }
    .babyBurglar .main5 .content .product_body {
      font-size: 0.09rem;
      color: #474747;
      width: 51%;
      margin-left: 0.2rem;
      max-width: 1.93rem; }
      .babyBurglar .main5 .content .product_body span {
        display: block;
        margin-bottom: 0.16rem; }
      .babyBurglar .main5 .content .product_body p {
        font-size: .09rem;
        color: #767676;
        line-height: .12rem; }
  .babyBurglar .indexmain4 {
    position: relative;
    background: url("../images/bj-ying-4.png") left top;
    background-size: 8rem 4.195rem; }
    .babyBurglar .indexmain4 .main4 {
      /*
        position: absolute;
        left: 0;
        top: 0;
        */
      width: 100%; }
      .babyBurglar .indexmain4 .main4 .title {
        text-align: center; }
        .babyBurglar .indexmain4 .main4 .title img {
          width: 0.945rem; }
      .babyBurglar .indexmain4 .main4 .mainContent {
        padding-bottom: 0.44rem;
        padding-top: 0.18rem; }
  .sharewheelchair .main2 .title img {
    width: 1.2055rem; }
  .sharewheelchair .main5 .title img {
    width: 0.5925rem; }
  .sharewheelchair .main5 .content {
    margin: 0.245rem 0 0.53rem; }
  .sharewheelchair .main5 .borderGreyDiv {
    font-size: 0.09rem;
    color: #474747; }
    .sharewheelchair .main5 .borderGreyDiv div {
      border: 1px solid #E1E3E3;
      border-radius: 0.04rem;
      width: 1.6rem;
      height: .62rem;
      padding: .1rem 0;
      margin: 0.0775rem 0.13rem; }
    .sharewheelchair .main5 .borderGreyDiv img {
      margin-bottom: 0.12rem;
      width: 0.325rem; }
  .sharewheelchair .main7 .title img {
    width: 1.16rem; }
  .sharewheelchair .indexmain4 {
    position: relative; }
    .sharewheelchair .indexmain4 .title {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center; }
      .sharewheelchair .indexmain4 .title img {
        width: 1.2055rem; }
  .sharewheelchair .indexmain6 {
    position: relative;
    min-height: 3.8rem;
    background: #124F9B; }
    .sharewheelchair .indexmain6 .main6 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%; }
      .sharewheelchair .indexmain6 .main6 .title {
        text-align: center; }
        .sharewheelchair .indexmain6 .main6 .title img {
          width: 0.59rem; }
      .sharewheelchair .indexmain6 .main6 .mainContent {
        padding-bottom: 0.33rem;
        padding-top: 0.3rem; }
  .escortbed .main2 .title img {
    width: 1.23625rem; }
  .escortbed .indexmain4 {
    position: relative; }
    .escortbed .indexmain4 .title {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center; }
      .escortbed .indexmain4 .title img {
        width: 1.365rem; }
  .escortbed .main5 .title img {
    width: 0.5925rem; }
  .escortbed .main5 .content {
    margin: 0.1925rem 0 0.3425rem; }
  .escortbed .main5 .borderGreyDiv {
    font-size: 0.09rem;
    color: #6D6D6D;
    flex-wrap: wrap; }
    .escortbed .main5 .borderGreyDiv div {
      border: 1px solid #E9EAEE;
      border-radius: 0.05rem;
      width: 1.005rem;
      height: 1.23rem;
      padding: 0.12rem 0.105rem;
      margin: 0.05rem 0.085rem; }
    .escortbed .main5 .borderGreyDiv p {
      line-height: 0.12rem; }
    .escortbed .main5 .borderGreyDiv .content_title {
      color: #474747;
      font-size: 0.1rem;
      padding: 0.12rem 0; }
    .escortbed .main5 .borderGreyDiv img {
      width: 0.36rem; }
  .escortbed .indexmain6 {
    position: relative;
    min-height: 2.49rem;
    background: #124F9B; }
    .escortbed .indexmain6 .main6 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%; }
      .escortbed .indexmain6 .main6 .title {
        text-align: center; }
        .escortbed .indexmain6 .main6 .title img {
          width: 0.59rem; }
      .escortbed .indexmain6 .main6 .mainContent {
        padding-bottom: 0.44rem;
        padding-top: 0.33rem; }
  .escortbed .main7 .title img {
    width: 0.59rem; }
  .escortbed .main7 .content {
    padding-top: 0.3rem; }
  .escortbed .main7 .flexViewC {
    background: #ffffff; }
    .escortbed .main7 .flexViewC .flex1 {
      width: 2.32rem; }
      .escortbed .main7 .flexViewC .flex1 p {
        padding: 0 0.315rem;
        color: #818181;
        font-size: 0.09rem;
        margin-top: 0.205rem; }
      .escortbed .main7 .flexViewC .flex1 span {
        padding: 0 0.315rem;
        color: #222222;
        font-size: 0.1rem; }
  .escortbed .main8 .title img {
    width: 0.59rem; }
  .escortbed .main8 .content {
    padding-top: 0.33rem;
    padding-bottom: .4rem; }
  .escortbed .main8 table {
    table-layout: fixed; }
    .escortbed .main8 table thead td:first-child {
      padding: 0;
      position: relative;
      background: linear-gradient(18deg, transparent 49.5%, #f5f5f5 49.5%, #f5f5f5 50.5%, transparent 50.5%); }
      .escortbed .main8 table thead td:first-child p {
        padding: 0rem 0.03rem; }
  .indexmain9 {
    position: relative;
    background-color: #0E95EF;
    /*
    background-color: #0B6CDB;
    */ }
    .indexmain9 .main9 {
      /*
      position: absolute;
      left: 0;
      top: 0;
      */
      width: 100%; }
      .indexmain9 .main9 .title {
        text-align: center; }
        .indexmain9 .main9 .title img {
          width: 0.59rem; }
      .indexmain9 .main9 .content {
        width: 94%;
        margin: 0 auto;
        /*
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        */ }
      .indexmain9 .main9 .content_body {
        color: #FFFFFF;
        font-size: 9px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: stretch; }
        .indexmain9 .main9 .content_body img {
          width: 0.245rem;
          padding-right: 0.1rem; }
        .indexmain9 .main9 .content_body .div_title {
          font-size: 0.09rem;
          padding-bottom: 0.1rem; }
        .indexmain9 .main9 .content_body .div_content {
          text-align: left;
          width: 50%;
          padding-left: 0.2225rem;
          padding-top: .15rem;
          padding-bottom: 0rem;
          padding-right: 0.23rem;
          margin-bottom: 0.405rem;
          margin-top: 0;
          position: relative; }
          .indexmain9 .main9 .content_body .div_content p {
            width: 1.38rem; }
      .indexmain9 .main9 .content_body:first-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.49); }
        .indexmain9 .main9 .content_body:first-child .div_content {
          padding-top: 0rem;
          padding-bottom: 0.15rem;
          margin-top: 0.235rem;
          margin-bottom: 0; }
        .indexmain9 .main9 .content_body:first-child .div_content:first-child:before {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 100%;
          background: rgba(255, 255, 255, 0.49); }
  .main10 .title img {
    width: 0.59rem; }
  .main10 .flexViewC {
    padding-top: 0.235rem;
    padding-bottom: 0.255rem; }
    .main10 .flexViewC .flex1 {
      margin: 0 0.02rem; } }
